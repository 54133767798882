<template>
  <div class="min-h-screen">
    <MaterialsShowView
      :fetch-route="materialShowRoute"
      @update-material="updateSeo($event)"
    />
  </div>
</template>
<script setup lang="ts">
import getAllRoutes from '~/composables/AppApiRoutes'
const { isAuthLoggedIn } = useMenuModel()
const isAuthenticated = isAuthLoggedIn()
const currentBranchId = useBranchId()
const materialShowRoute =
  getAllRoutes(isAuthenticated).materialsRoutes.materialShowByServer
const { url } = useDomainHost()
const { t } = useI18n()
const localePath = useLocalePath()
function updateSeo(material: Material) {
  useServerSeoMeta(
    useOgMeta(
      url + '/materials/' + material?.id,
      material?.name,
      material?.summary,
      material?.thumb
    )
  )
  useHead({
    title: material?.name,
    meta: [
      {
        hid: 'description',
        name: 'description',
        content: material?.summary
      }
    ],
    link: [useSeoCanonical(url + '/materials/' + material?.id)],
    script: [
      useSeoBreadcrumb([
        useSeoBreadcrumbItem(1, t('home'), url),
        useSeoBreadcrumbItem(
          2,
          material?.name,
          url + '/materials/' + material?.id
        )
      ]),
      material ? useSeoProduct(material) : {}
    ]
  })

  if (material?.store?.typeIsDelivering) {
    const newPath = localePath(localePath(useRouteMaterial(material, currentBranchId, true)))
    useRouter().replace(newPath).then(() => reloadNuxtApp({
      path: newPath,
      ttl: 1
    }))
  }
}
</script>
